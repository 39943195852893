import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import AboutUs from '../pages/AboutUs';
import Contact from '../pages/Contact';
import GeneralEngineering from '../pages/GeneralEngineering';
import Home from '../pages/Home';
import Home2 from '../pages/Home2';
import InteriorRemodeling from '../pages/InteriorDesign';
import OurTeam from '../pages/OurTeam';
import Services from '../pages/Services';
import Structural from '../pages/Structural';


const Routers =()=> {
  return (
    <Routes>
    <Route path='/' element={<Navigate to='/Home'/>} />
      <Route path='home' element={<Home/>} />

      <Route path='aboutus' element={<AboutUs/>} />
      <Route path='services' element={<Services/>} />
      <Route path='our-team' element={<OurTeam/>} />
      <Route path='contact' element={<Contact/>} />
      <Route path='general-construction' element={<GeneralEngineering/>} />
      <Route path='structural' element={<Structural/>} />
      <Route path='interior-remodeling' element={<InteriorRemodeling/>} />
    
    </Routes>
  )
}
export default Routers;