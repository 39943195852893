import React, { useEffect,useState } from "react";
import {Helmet} from"react-helmet";
import {motion} from "framer-motion"
import $ from 'jquery';
import '../components/Styles/contact.css'
import service1 from '../images/service1.png';
  

const Contact =()=> {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setPhonenumber] = useState('');
  const [message, setMessage] = useState('');



  const  handleSubmit = event => {
    event.preventDefault(); // 👈️ prevent page refresh
    var fname =document.getElementById('fname').value;
    var lname =document.getElementById('lname').value;
    var number =document.getElementById('number').value;
     var email =document.getElementById('email').value;
    var message =document.getElementById('message').value;
    var body = 'First Name: ' +fname + '<br/> Last Name: ' +lname +'<br/> Contact Number: ' +number  + '<br/> Email: ' +"<a href='mailto:''+email+''>"+email+"</a>"+  '<br/> Message: ' +message;
    
    
    if(fname === ""){
      alert("Please enter a first name to continue");
      $('#fname').focus();
      return false;
   }
   if(lname === ""){
     alert("Please enter a last name to continue");
     $('#lname').focus();
     return false;
  }
  if(email === ""){
    alert("Please enter a email to continue");
    $('#email').focus();
    return false;
  }
  if(number === ""){
   alert("Please enter a contact number to continue");
   $('#number').focus();
   return false;
}

if(fname !== '' && lname !== '' && number !== ''&& email!== '') {

    window.Email.send({
      Host : "smtp.elasticemail.com",
      Username : "team@jaycoconstructions.com",
      Password : "520A225D2B641AE6B00A7D034DA5BFAA83FF",
      To : 'team@jaycoconstructions.com',
      From : 'team@jaycoconstructions.com',
      Subject : "Message",
      Body : body
  }).then(
    message => alert("Message Sent"),

    setFirstName(''),
    setLastName(''),
    setEmail(''),
    setPhonenumber(''),
    setMessage('')
  );

  }


 };

  
  return (
    <div>
               <Helmet>
               <title>Contact Us - Jayco Construction</title>
                <meta name="description" content="If you have questions about Jayco Construction, contact us today and discover more about our amazing & affordable all construction services from anywhere in USA!"/>
                <script src="https://smtpjs.com/v3/smtp.js"></script>
                  </Helmet>
      <section className='about__us'>
        <h1>Contact Us</h1>
        <h2>Get Your Dream Home - Schedule a Consultation Now !</h2>
        </section>

        <section className='contact__section' >
          
        <form onSubmit={handleSubmit}>

          <div className='contact__row' >

             <div className='contact__col1' >
              <label>First Name</label><br/>
              <input placeholder='First Name' id="fname" onChange={event => setFirstName(event.target.value)}
          value={firstName}
 required></input>
             </div>
             <br/>
             <div className='contact__col1' >
              <label>Last Name</label><br/>
              <input placeholder='Last Name' id="lname"            onChange={event => setLastName(event.target.value)}
          value={lastName}
 required></input>
             </div>
          </div>
          <br/>
          <label >Email</label><br/>
          <input  type='email' placeholder='Email' id="email"           onChange={event => setEmail(event.target.value)}
          value={email}
 required></input>
          <br/><br/>
          <label >Phone Number</label><br/>
          <input  type='number' placeholder='Phone Number'id="number"           onChange={event => setPhonenumber(event.target.value)}
          value={number}
  required></input>
          <br/><br/>

          <label >Message</label><br/>
          <textarea  placeholder='Message' id="message" rows={6}             onChange={event => setMessage(event.target.value)}
          value={message}
></textarea>
          <div className='btn__con' >

          <button type="submit" id=''>Send Message</button>

          
          </div>
          </form>
          </section>

          <section className='contact__center'>
          <div className='achive__a' >
          <h2>Contact</h2>
          <hr/>
        </div>
       
       <div className="contact__row">
        <div className="contact__col2" >
            <h2>Contact Info</h2>
            <br/>
            <h2>Email</h2>
            <h3><a href="mailto:team@jaycoconstructions.com">team@jaycoconstructions.com</a></h3>
            <br/><br/>
            <h2>Phone</h2>
            
            <h2 >Jay Nassar</h2>
            <h3 >President of Jayco Constructions</h3>
            <a href="tel:914 690 4557">Contact: 914 690 4557</a>
            <br/> <br/>
            <h2 >Moe Nassar</h2>
<h3 >Projects Manager</h3>
<a href="tel:914-690-4557">Contact: 914 690 4557</a>
<br/><br/>
            <h2 >Ellie Manson</h2>
<h3 >Administrative Director</h3>
<a href="tel:917 809 8225">Contact: 917 809 8225</a>

            <br/><br/>
            <h2>Address</h2>
            <h3>2800 Post Oak Blvd, Suite 4100, Houston, TX 77056, USA </h3>
        </div>

       </div>
          </section>
          <section className="location__map" >
          <iframe title='map' className='mapcon' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.3943005276515!2d-95.46552238254637!3d29.73729969083219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16c2cf13989%3A0x9fd2bbab78247d62!2s2800%20Post%20Oak%20Blvd%2C%20Houston%2C%20TX%2077056%2C%20USA!5e0!3m2!1sen!2s!4v1675405427489!5m2!1sen!2s"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          
          </section>
          <br/><br/>
    </div>
  )
}
export default Contact;