import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import logo from './logo2.png';

 const Footer =()=> {
  return (
    <>
      <section className="footer__section">
      <div className="footer__width">
      <div className="row">

        <div className='footer_col' >
        <img src={logo}  alt="logo"></img>

          <p>Jayco Construction: Transforming residential and commercial spaces with expert craftsmanship and innovative solutions. Contact us for exceptional results.
 </p>
        </div>
 
        <div className='footer_col'  style={{flexBasis:"18%"}}>
          
        <h3>Pages</h3>

        <a href='aboutus' className='link__footer'>About Us</a>
        <a href='services' className='link__footer'>Services</a>
        <a href='our-team' className='link__footer'>Our Team</a>
        <a href='contact' className='link__footer'>Contact Us</a>
        
        </div>
 
        <div className='footer_col' >
        <h3>Contact</h3>
        <div className='contact_detil'>
        <i class="ri-phone-fill"></i>
        <span>
        <a href="tel:914-690-4557">914 690 4557</a>
        </span>
      
        </div>
        <div className='contact_detil'>
        <i class="ri-mail-fill"></i>
        <span><a href="mailto:team@jaycoconstructions.com">team@jaycoconstructions.com</a></span>
        </div>
        <div className='contact_detil'>
        <i class="ri-map-pin-fill"></i>
        <span>2800 Post Oak Blvd, Suite 4100, Houston, TX 77056, USA </span>
        </div>     

        </div>
 
 <div className='footer_col' style={{display:"none"}}>
            <h3>Social Media</h3>
          <div className='social__media'>
          <i class="ri-facebook-fill"></i>
          <i class="ri-twitter-fill"></i>
          <i class="ri-linkedin-box-fill"></i>
          <i class="ri-instagram-fill"></i>
          </div>
        </div>
 
        </div>
        </div>

      </section> 
    </>
  )
}
export default Footer;