import React from 'react'
import {motion} from "framer-motion"
import {Helmet}from"react-helmet";
import '../components/Styles/AboutUs.css'
import about1 from '../images/About1.png';
import about2 from '../images/About2.png';
import circle from '../images/vector0.png';
import vector2 from '../images/Vector2.png';
import vector from '../images/Vector.png';
import vector1 from '../images/Vector1.png';
import home2pic3b from '../images/pro2-min.jpg'
import home2pic3c from '../images/pro3c.jpg'
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


 const AboutUs =()=> {
  useEffect(()=>{
    AOS.init();
  })

  return (
    <div>
        <Helmet>
      
      <title>About US - Jayco Construction</title>
      <meta name="description" content="Jayco provides the best construction services that specialize in delivering high-quality and cost-effective building solutions for residential and commercial clients! including Structural Engineering, Interior Remodeling & General Construction."/>
      
      </Helmet>

      <section className='about__us'>
        <h1>About</h1>
        <h2>Jayco Construction is a full-service construction company that has been delivering exceptional results for over 15 years. We specialize in custom-oriented solutions and attention to detail.</h2>
        <img src={about1} alt="center_first_pic"/>

      </section>


      <section className='about__achievment'>
        
        <div className='achive__a' >
          <h2>ACHIEVEMENT</h2>
          <hr/>
        </div>
        
        <h3 data-aos="zoom-in">We are pleased to have finished a wide range of jobs, both residential and commercial, and have received recognition and awards for our efforts.</h3>
        
        <div className='achivment__row'>
       
            <div className="achivment__col1" data-aos="fade-right">
            <img src={about2} alt="center_first_pic"/>  
            </div>
       
            <div className="achivment__col2" data-aos="flip-right">
                <div>
                <h2>500+</h2>
          <p>Project Completed</p>
          <br/>

          <h2>23+</h2>
          <p>Professional Teams</p>
          <br/>
          
          <h2>15+</h2>
          <p>Years Experience</p>

                </div>
            </div>
        </div>
      
      </section>

     <section className="about__service">
       <div className='achive__a'>
          <h2>SERVICE</h2>
          <hr/>
        </div>
        <h3 id='about__h3' data-aos="zoom-in">Why Choose Us</h3>
        <h4 data-aos="zoom-in">Jayco Construction is a reputable construction company that specializes in delivering high-quality and cost-effective building solutions for residential and commercial clients.</h4>
        
        <div className='service__row'>
          <div className="service__col">
            <div   className="service__bx" data-aos="flip-right">
            <img src={vector2} id="imgcircle" alt="center_first_pic"/>
            <img src={circle} alt="center_first_pic"/>
             <h2 id='bx__h2'>Tailored Solutions:</h2>
             <h3 id='bx__h3'>We craft personalized experiences, not just buildings, by tailoring our services to each client's unique needs and preferences.</h3>
            </div>
          </div>
          <div className="service__col">
          <div   className="service__bx" data-aos="flip-left">
 
          <img src={vector} id="imgcircle" alt="center_first_pic"/>
            <img src={circle} alt="center_first_pic"/>
             <h2 id='bx__h2'>Professional Standards:</h2>
             <h3 id='bx__h3'>We set the bar high and always aim for excellence by maintaining high standards, certifications, and a commitment to safety and quality.</h3>
            </div>
          </div>
          <div className="service__col">
          <div   className="service__bx" data-aos="flip-right">
          <img src={vector1} id="imgcircle" alt="center_first_pic"/>
            <img src={circle} alt="center_first_pic"/>
             <h2 id='bx__h2'>Innovative Design:</h2>
             <h3 id='bx__h3'>We are not just builders but problem solvers who use cutting-edge technology and innovative building methods to offer creative solutions that stand out in the field.</h3>
            </div>
          </div>
        </div>
        
         </section>
         <section className='last__banner' data-aos="zoom-in-up">
       <div className='last__banner__width'>
       <div className='last__banner__row'>
         <div className="last__banner__width__col">
          <h2>Schedule a Consultation Now!</h2>
         </div>
         <div className="last__banner__width__colb">
          <br/>
          <h2>Transform both your commercial and residential spaces with craftsmanship  </h2>
<br/>
          <a  href='contact'>Contact Us</a>
          <br/>   <br/>
         </div>
       </div>
       </div>
      </section>

    </div>
  )
}
export default AboutUs;