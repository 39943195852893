import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import {Helmet}from"react-helmet";
import center_first_pic from '../images/center_first_pic.jpg';
import c_two_pic1 from '../images/c_two_pic1.jpg';
import center_2_pic from '../images/center_2_pic.jpg';
import center_3_pic from '../images/center_3_pic.jpg';
import center_4_pic from '../images/center_4_pic.jpg';
import center_5_pic from '../images/center_5_pic.jpg';
import center_6_pic from '../images/center_6_pic.jpg';
import center_7_pic from '../images/center_7_pic.jpg';
import center_8_pic from '../images/center_8_pic.jpg';
import banner_p1 from '../images/banner_p1.png';
import banner_p2 from '../images/banner_p2.png';
import banner_p3 from '../images/banner_p3.png';
import banner_p4 from '../images/banner_p4.png';
import banner_p5 from '../images/banner_p5.png';
import uppic from '../images/uppic.png';
import center_third_p1 from '../images/center_third_p1.jpg';
import center_third_p2 from '../images/center_third_p2.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Home =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Engineering, Interior Remodeling & Construction Company - Jayco Construction</title>
      <meta name="description" content="We exist to build great things! Jayco Construction is a leading engineering construction company provides General Engineering, Interior Remodeling & Construction services."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' id='top__main'>
      <div className='container_top' data-aos="fade-right">
      <h1>Building the future, one project at a time</h1>
      <p>Jayco Construction provides exceptional construction services for a variety of projects.
</p>
      </div>
    
    </section>

    <section className='card_bx' >
      <div className="row"> 
     
    <motion.div  whileHover={{scale: 1.1,}} className="card_col card_bg_color" data-aos="zoom-in">
     <h3>Custom-oriented</h3>
     <p>At Jayco Construction we believe that every project requires a personalized approach. That's why we are custom-oriented and dedicated to delivering solutions tailored to your specific needs and preferences.
     </p>
    </motion.div> 
    
    <motion.div  whileHover={{scale: 1.1,}} className="card_col card_bg_color2" data-aos="zoom-in">
     <h3>Visionary</h3>
     <p>Jayco Construction is not only building structures but crafting visionary solutions that anticipate future trends and push the boundaries of design and technology.
     </p>
    </motion.div> 

    <motion.div  whileHover={{scale: 1.1,}} className="card_col card_bg_color" data-aos="zoom-in">
     <h3>Professional</h3>
     <p>Jayco Construction provides professional services, safety, and quality, as well as maintaining high standards and certifications to ensure you receive the best results.
     </p>
    </motion.div> 

      </div>
 </section>


<section className='banner1' >

<div className="row" data-aos="zoom-in-up"> 
<div className='banner1_col'>
<h3>15+</h3>
<p>Years of Experience</p>
</div>

<div className='banner1_col'>
<h3>500+</h3>
<p>Projects Executed</p>
</div>

<div className='banner1_col'>
<h3>23+</h3>
<p>Professional Teams </p>
</div>

<div className='banner1_col'>
<h3>500+</h3>
<p>Satisfied Clients</p>
</div>

</div>

</section>


<section className='center_first'>

<div className="row"> 
<div className='center_first_col' data-aos="fade-right">
<h3>Our Design Philosophy</h3>
<p>Functionality, aesthetics, and sustainability are the three main tenets of Jayco Construction's design philosophy. We think a building should not only be useful and fulfill its intended role, but it should also be staggeringly pleasing and enrich the surrounding. To lessen our impact on the environment, we also consider how important sustainability is and use eco-friendly solutions whenever we can. Our team of skilled specialists will collaborate closely with you to materialize your idea while keeping these guidelines in mind, from the initial concept to the finished product.</p>
</div>

<div className='center_first_col' data-aos="fade-left">
<img src={center_first_pic} alt="center_first_pic"></img>
</div>
</div>
</section>



<section className='center_second'>
<h2>Our Work</h2>
<div className="row" data-aos="zoom-in"> 

<div className='center_second_col'>
<motion.img whileHover={{scale: 0.9,}} src={c_two_pic1} alt="center_first_pic"/>
</div>

<div className='center_second_col'>
<motion.img whileHover={{scale: 0.9,}} src={center_2_pic} alt="center_first_pic"/>
</div>

<div className='center_second_col'>
<motion.img whileHover={{scale: 0.9,}} src={center_3_pic} alt="center_first_pic"/>
</div>
<div className='center_second_col'>
<motion.img whileHover={{scale: 0.9,}} src={center_4_pic} alt="center_first_pic"/>
</div>

<div className='center_second_col'>
<motion.img whileHover={{scale: 0.9,}} src={center_5_pic} alt="center_first_pic"/>
</div>

<div className='center_second_col'>
<motion.img whileHover={{scale: 0.9,}} src={center_6_pic} alt="center_first_pic"/>
</div>

<div className='center_second_col'>
<motion.img whileHover={{scale: 0.9,}} src={uppic} alt="center_first_pic"/>
</div>

<div className='center_second_col'>
<motion.img whileHover={{scale: 0.9,}} src={center_7_pic} alt="center_first_pic"/>
</div>

<div className='center_second_col'>
<motion.img whileHover={{scale: 0.9,}} src={center_8_pic} alt="center_first_pic"/>
</div>
</div>

</section>
<section className='caursol__section'>
<h2>Our Work</h2>
    <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="10000">
      <img src={c_two_pic1} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src={center_2_pic} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={center_3_pic} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={center_4_pic} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={center_5_pic} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={uppic} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={center_7_pic} class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src={center_8_pic} class="d-block w-100" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</section>    



<section className='banner_2' >
<div className="row" data-aos="zoom-in-up"> 
<div className='banner2_col' >
<img src={banner_p1} alt="center_first_pic"></img>
</div>

<div className='banner2_col'>
<img src={banner_p2} alt="center_first_pic"></img>
</div>

<div className='banner2_col'>
<img src={banner_p3} alt="center_first_pic"></img>
</div>

<div className='banner2_col'>
<img src={banner_p4} alt="center_first_pic"></img>
</div>
<div className='banner2_col'>
<img src={banner_p5} alt="center_first_pic"></img>
</div>
</div>

</section>



<section className='center_third'>
<div className="row"> 
<div className='center_third_col' data-aos="fade-right">
<img src={center_third_p1} id="home__img__lsta" alt="center_first_pic"></img>
</div>

<div className='center_third_col' data-aos="fade-left">
<img src={center_third_p2} id="home__img__lstb" alt="center_first_pic"></img>
<p>Explore a few of our earlier projects to get a sense of the caliber and attention to detail we bring to each project. Our portfolio, which ranges from residential to commercial, demonstrates the variety and sophistication of our team. Our portfolio is a monument to the success of our custom-oriented approach and our devotion to producing great solutions for our clients, whether you're seeking for inspiration or confidence of our talents.</p>
</div>
</div>

</section>


    </div>
  )
}
export default Home;