import React from 'react'
import {motion} from "framer-motion"
import '../components/Styles/Structural.css'
import {Helmet}from"react-helmet";
import general1 from '../images/general1.png';
import general2 from '../images/general2.png';
import general3 from '../images/general3.png';
import general4 from '../images/general4.png';
import general5 from '../images/general5.png';
import general6 from '../images/general6.png';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

 const GeneralEngineering =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      <title>Complete General Construction Services - Jayco Construction </title>
      <meta name="description" content="We knows how to bring your building to life on time and on budget. Jayco offer General Construction i.e, Roofing & Exterior services, drywall installation, deck building services & more. Visit Us!"/>
      </Helmet>

      <section className='about__us'>
        <h1>General Construction</h1>
        <h2>Welcome to Jayco Construction's General Construction page! We are a full-service construction company specializing in all aspects of construction, from small renovations to large-scale projects. Our team of experienced and skilled professionals provides high-quality work and excellent customer service.</h2>
        <img src={general1} alt="main"/>
       
       </section>

    <section className="structural__center">
       <h4 data-aos="zoom-in">Our services include:</h4>
       <br/><br/>
        <div className="struct__row">
            <div className="struct__col1" data-aos="fade-right">
                <h2>Roofing and Exteriors Services</h2>
                <p>We provide a variety of roofing and exterior services, such as roof installation, repair, and maintenance. Along with installing windows, doors, and siding on the outside, we also provide gutter and downspout services. </p>
                <br/>
                
                <h2>Drywall installation</h2>
                <p>We have a team of specialists in drywall installation, repair, and finishing. Whether it's a new build, remodel, or repair, we can assist you with all of your drywall needs. </p>

            </div>
            
            <div className="struct__col1" data-aos="fade-left">
                <h2>Custom Crown Molding Installation</h2>
                <p>We also offer custom crown molding installation services. In addition to installing crown molding, our team of experts can also match existing molding or produce a bespoke design to meet your demands in any room in your house.</p>
                <br/>
                <h2>Electric Fireplace Installation</h2>
                <p>We provide installation services for electric fireplaces. We can assist you in selecting the ideal electric fireplace for your room and guarantee a satisfactory installation.</p>
 
            </div>

        </div>

        <br/>
      <br/>
        <div className="struct__row">
               <div className="struct__col1" data-aos="fade-right">
               <img src={general2} alt="main"/>
              </div>
               <div className="struct__col1" data-aos="fade-left">
               <img src={general3} alt="main"/>
               </div>
            </div>
            <br/>
            <img src={general4} alt="main" data-aos="zoom-in-up"/>
            <br/>
            <br/>
       
            <div className="struct__row">
               <div className="struct__col1" data-aos="fade-right">
               <img src={general5} alt="main"/>
              </div>
               <div className="struct__col1" data-aos="fade-left">
               <img src={general6} alt="main"/>
               </div>
            </div>
            <br/>
<br/>
            <div className="struct__row">
            <div className="struct__col1" data-aos="fade-right">
                <h2>Gas Fireplace Installation</h2>
                <p>We also provide installation services for gas fireplaces. We can assist you in selecting the ideal gas fireplace for your room and guarantee a satisfactory installation. </p>
                 <br/>
                 <h2>Deck Building</h2>
                <p>From concept to construction, we provide deck building services. Whether you want a straightforward deck or a more complex outdoor living area, we can help you design and build the deck of your dreams. </p>

            </div>
            
            <div className="struct__col1" data-aos="fade-left" >
                <h2>Energy-efficient home construction</h2>
                <p>We are dedicated to creating environmentally friendly homes that also help you save money on energy expenditures. We can assist you in planning and constructing an energy-efficient home that suits your individual requirements and tastes.</p>
 
            </div>

        </div>

    </section>

    <section className='last__banner' data-aos="zoom-in-up">
       <div className='last__banner__width'>
       <div className='last__banner__row'>
         <div className="last__banner__width__col">
          <h2>Schedule a Consultation Now!</h2>
         </div>
         <div className="last__banner__width__colb">
          <br/>
          <h2>Transform both your commercial and residential spaces with craftsmanship </h2>
<br/>
          <a  href='contact'>Contact Us</a>
          <br/>   <br/>
         </div>
       </div>
       </div>
      </section>



    </div>
  )
}
export default GeneralEngineering;