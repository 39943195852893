import React from 'react'
import {motion} from "framer-motion"
import '../components/Styles/Structural.css'
import {Helmet}from"react-helmet";
import generalcon1 from '../images/generalcon1.png';
import generalcon2 from '../images/generalcon2.png';
import generalcon3 from '../images/generalcon3.png';
import generalcon4 from '../images/generalcon4.png';
import generalcon5 from '../images/generalcon5.png';
import generalcon6 from '../images/generalcon6.png';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Structural =()=> {
  
    useEffect(()=>{
           AOS.init();
         })
  
  return (
    <div>
                  <Helmet>
      
      <title>Structural Engineering Consultants & Design Services - Jayco Construction</title>
      <meta name="description" content="Jayco Construction structural engineering experts have diverse expertise to provide unparalleled resources and deliver designs for a successful project."/>
      
      </Helmet>

      <section className='about__us'>
        <h1>Structural Engineering</h1>
        <h2>Jayco Construction is a leading provider of structural engineering services for a wide range of projects. Our team of experienced and highly skilled engineers is dedicated to ensuring the safety and integrity of every structure we work on.</h2>
        <img src={generalcon1} alt="main"/>
       </section>

    <section className="structural__center" >
    <h4 data-aos="zoom-in">Our structural engineering services include:</h4 >
       <br/><br/>

        <div className="struct__row">
            <div className="struct__col1" data-aos="fade-right">
                <h2>Building Design and Analysis:</h2>
                <p>Our team will work with you to design a structurally sound building that compiles with all relevant rules and regulations. We assess the building's structure using the most up-to-date tools and methods to make sure it can sustain all loads and stresses.</p>
                 <br/>
                 <h2>Foundation Design:</h2>
                <p>We specialize in designing foundations for a range of structures, including buildings, bridges, and towers. Our foundation designs consider soil conditions, site constraints, and other factors to ensure the structure is solid and secure.</p>
            
            </div>
            
            <div className="struct__col1" data-aos="fade-left">
                <h2>Seismic Analysis and Design:</h2>
                <p>We have the knowledge to assess and build structures that can withstand seismic forces. This entails determining the seismic risk, examining the structural system, and creating a structure that can withstand seismic forces. </p>
                <br/>
                 <h2>Bridge Design:</h2>
                <p>Our team is experienced in the design of bridges of all types and sizes. We use modern software and techniques to design bridges that are secure, durable, and cost-effective.</p>
 
            </div>

        </div>

           <br/><br/>
        <div className="struct__row">
               <div className="struct__col1" data-aos="fade-right">
               <img src={generalcon2} alt="main"/>
              </div>
               <div className="struct__col1" data-aos="fade-left">
               <img src={generalcon3} alt="main"/>
               </div>
            </div>
            <br/>
            <img src={generalcon4} alt="main" data-aos="zoom-in-up"/>
            <br/>
            <br/>
       
            <div className="struct__row">
               <div className="struct__col1" data-aos="fade-right">
               <img src={generalcon5} alt="main"/>
              </div>
               <div className="struct__col1">
               <img src={generalcon6} alt="main" data-aos="fade-left"/>
               </div>
            </div>
            <br/><br/>
            <div className="struct__row">
            <div className="struct__col1" data-aos="fade-right">
                <h2>Retaining Walls:</h2>
                <p>We create retaining walls for a range of uses, such as stabilizing steep slopes and holding soil in place. To make sure the walls are sturdy and solid, our designs take into account the soil characteristics, site limitations, and other elements. </p>
                 <br/>
                 <h2>Building Inspections and Reports:</h2>
                <p>Our staff can examine your current building and offer thorough summaries of any problems or areas that require improvement. </p>
            </div>
            
            <div className="struct__col1" data-aos="fade-left">
                <h2>Project Management:</h2>
                <p>To guarantee that the project is finished on schedule, within budget, and to the greatest standards possible, we offer project management services.</p>
 
            </div>

        </div>
        <p data-aos="zoom-in-up">We at Jayco Construction are dedicated to giving our customers the best structural engineering services possible. To find out how we can assist with your upcoming project, get in touch with us right now.</p>
    </section>


    <section className='last__banner' data-aos="zoom-in-up">
       <div className='last__banner__width'>
       <div className='last__banner__row'>
         <div className="last__banner__width__col">
          <h2>Schedule a Consultation Now!</h2>
         </div>
         <div className="last__banner__width__colb">
          <br/>
          <h2>Transform both your commercial and residential spaces with craftsmanship  </h2>
<br/>
          <a  href='contact'>Contact Us</a>
          <br/>   <br/>
         </div>
       </div>
       </div>
      </section>

    </div>
  )
}
export default Structural;