import React from 'react'
import {motion} from "framer-motion"
import '../components/Styles/ourteam.css'
import {Helmet}from"react-helmet";
import ourteam1 from '../images/ourteam1.png';
import ourteam2 from '../images/ourteam2.png';
import ourteam3 from '../images/ourteam3.png';
import ourteam4 from '../images/ourteam4.png';
import ourteam5 from '../images/ourteam5.png';
import ourteam6 from '../images/ourteam6.png';
import ourteam7 from '../images/ourteam7.png';
import circle from '../images/vector0.png';
import vector2 from '../images/Vector2.png';
import vector from '../images/Vector.png';
import vector1 from '../images/Vector1.png';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


 const OurTeam =()=> {
  useEffect(()=>{
    AOS.init();
  })
  
  return (
    <div>
                    <Helmet>
      
      <title>Our Team - Jayco Constructions</title>
      <meta name="description" content="Jayco Constructions has a dedicated team of engineers, architects, project managers, & other professionals who provide quality construction services for small to complex building requirements."/>
      
      </Helmet>
      <section className='about__us' data-aos="zoom-in">
        <h1>Our Team</h1>
        <img src={ourteam1} alt="main"/>
       </section>
       
       <section className='team__details'>
         <h2 data-aos="fade-right">Jay Nassar</h2>
         <h3 data-aos="fade-right">President of Jayco Constructions</h3>
        <p data-aos="fade-right">Jay Nassar, the president of Jayco Constructions, a reputable construction company renowned for its dedication to excellence and creative approach to construction projects. Jay offers a lot of knowledge and skills to the team with his more than 20 years of experience in the sector.<br/><br/>
Jay worked his way up the corporate ladder before taking the helm of Jayco Constructions, beginning as a project manager and managing a number of significant building initiatives. He received swift recognition for his remarkable leadership abilities and meticulous attention to detail, which led to his promotion to a senior management position.
<br/><br/>Jay has led Jayco Constructions to become a prosperous and well-known construction firm that completes high-caliber projects for customers in the commercial, residential, and industrial sectors. 
<br/><br/>Jay is committed to seeing that every project is done to the highest standards, on schedule, and within the allotted budget.
<br/><br/>In addition to belonging to the National Association of Home Builders and the Associated General Contractors of America, Jay has a degree in construction management. He is devoted to offering the best quality of service to the clients of Jayco Constructions and is committed to staying current with the most recent building technology and processes. </p>


<br/> 
<br/><br/>
<h2 data-aos="fade-right">Ellie Manson</h2>
<h3 data-aos="fade-right">Administrative Director</h3>
<p data-aos="fade-right">Ellie Manson holds the position of Administrative Director at Jayco Constructions, where he is in charge of overseeing the day-to-day operations of the business. Ellie adds a lot of knowledge and expertise to the team with his more than 10 years of experience in managerial and administrative positions.
<br/><br/>
Ellie is renowned for his great attention to detail and organizational abilities, which he employs to make sure Jayco Constructions operates smoothly. He is responsible for leading a group of administrative employees and collaborates closely with other departments to plan and organize assignments and projects.
</p>
<br/><br/>
<h2 data-aos="fade-right">Amos Keeton</h2>
<h3 data-aos="fade-right">District Manager</h3>
<p data-aos="fade-right">Amos Keeton is the District Manager for our Jayco Constructions office in Houston, Texas. He adds to the team an experience of three years in the construction industry. He is responsible for overseeing all the operations within this district, including managing project timelines, customer relationships and budgets.
<br/><br/>Amos has a proven track record of completing effective projects on time and budget. He has created successful management techniques for the specific issues that building projects can offer since he has a thorough awareness of them.

</p>
<br/>
       </section>
       
       <section className='ourteam__center' style={{display:"none"}}>
       <div className='achive__a'>
          <h2>Designer</h2>
          <hr/>
        </div>
       <br/>
       <div className='mid__div__two' data-aos="fade-right">
       <h2>Creative Person</h2>
       <p>Customize your interior design into a dream place with the best designers and quality furniture. We try our best to fulfill your expectations.</p>
       </div>
       <br/>
         <div className='row'>
            <div className="ourteam__col1" >
            <div >
                <h3>Shoo Phar Dhie</h3>
                <h4>CEO</h4>
            </div>
           <motion.img whileHover={{scale: 0.9,}} src={ourteam2} alt="profile"/>
            </div>
            <div className="ourteam__col1" >
            <div>
                <h3>Shoo Phar Dhie</h3>
                <h4>CEO</h4>
            </div>
           <motion.img whileHover={{scale: 0.9,}} src={ourteam3} alt="profile"/>
            </div>
            <div className="ourteam__col1" >
            <div>
                <h3>Shoo Phar Dhie</h3>
                <h4>CEO</h4>
            </div>
           <motion.img whileHover={{scale: 0.9,}} src={ourteam4} alt="profile"/>
            </div>
            <div className="ourteam__col1" >
            <div>
                <h3>Shoo Phar Dhie</h3>
                <h4>CEO</h4>
            </div>
           <motion.img whileHover={{scale: 0.9,}} src={ourteam5} alt="profile"/>
            </div>
            
         
         </div>
         
         <div className='row'>
            <div className="ourteam__col1" >
            <div>
                <h3>Shoo Phar Dhie</h3>
                <h4>CEO</h4>
            </div>
           <motion.img whileHover={{scale: 0.9,}} src={ourteam6} alt="profile"/>
            </div>
            <div className="ourteam__col1" >
            <div>
                <h3>Shoo Phar Dhie</h3>
                <h4>CEO</h4>
            </div>
           <motion.img whileHover={{scale: 0.9,}} src={ourteam7} alt="profile"/>
            </div>
            <div className="ourteam__col1" >
            <div>
                <h3>Shoo Phar Dhie</h3>
                <h4>CEO</h4>
            </div>
           <motion.img whileHover={{scale: 0.9,}} src={ourteam2} alt="profile"/>
            </div>
            <div className="ourteam__col1" >
            <div>
                <h3>Shoo Phar Dhie</h3>
                <h4>CEO</h4>
            </div>
           <motion.img whileHover={{scale: 0.9,}} src={ourteam3} alt="profile"/>
            </div>
            
         
         </div>

       </section>
  
       <section className="about__service">
       <div className='achive__a'>
          <h2>SERVICE</h2>
          <hr/>
        </div>
        <h3 id='about__h3' data-aos="zoom-in">Why Choose Us</h3>
        <h4 data-aos="zoom-in">Jayco Construction is a reputable construction company that specializes in delivering high-quality and cost-effective building solutions for residential and commercial clients.</h4>
        
        <div className='service__row'>
          <div className="service__col">
            <div   className="service__bx" data-aos="flip-right">
            <img src={vector2} id="imgcircle" alt="center_first_pic"/>
            <img src={circle} alt="center_first_pic"/>
             <h2 id='bx__h2'>Tailored Solutions:</h2>
             <h3 id='bx__h3'>We craft personalized experiences, not just buildings, by tailoring our services to each client's unique needs and preferences.</h3>
            </div>
          </div>
          <div className="service__col">
          <div   className="service__bx" data-aos="flip-left">
 
          <img src={vector} id="imgcircle" alt="center_first_pic"/>
            <img src={circle} alt="center_first_pic"/>
             <h2 id='bx__h2'>Professional Standards:</h2>
             <h3 id='bx__h3'>We set the bar high and always aim for excellence by maintaining high standards, certifications, and a commitment to safety and quality.</h3>
            </div>
          </div>
          <div className="service__col">
          <div   className="service__bx" data-aos="flip-right">
          <img src={vector1} id="imgcircle" alt="center_first_pic"/>
            <img src={circle} alt="center_first_pic"/>
             <h2 id='bx__h2'>Innovative Design:</h2>
             <h3 id='bx__h3'>We are not just builders but problem solvers who use cutting-edge technology and innovative building methods to offer creative solutions that stand out in the field.</h3>
            </div>
          </div>
        </div>
        
         </section>
         <section className='last__banner' data-aos="zoom-in-up">
       <div className='last__banner__width'>
       <div className='last__banner__row'>
         <div className="last__banner__width__col">
          <h2>Schedule a Consultation Now!</h2>
         </div>
         <div className="last__banner__width__colb">
          <br/>
          <h2>Transform both your commercial and residential spaces with craftsmanship </h2>
<br/>
          <a  href='contact'>Contact Us</a>
          <br/>   <br/>
         </div>
       </div>
       </div>
      </section>



    </div>
  )
}
export default OurTeam;