import React from 'react'
import {motion} from "framer-motion"
import '../components/Styles/Structural.css'
import {Helmet}from"react-helmet";
import interior1 from '../images/interior1.png';
import interior2 from '../images/interior2.png';
import interior3 from '../images/interior3.png';
import interior4 from '../images/interior4.png';
import interior5 from '../images/interior5.png';
import interior6 from '../images/interior6.png';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


 const InteriorRemodeling =()=> {
  
  useEffect(()=>{
    AOS.init();
  })

  return (
    <div>
              <Helmet>
      
      <title>Interior Remodeling - Complete Home Renovation Services - Jayco Construction</title>
      <meta name="description" content="We Can Achieve Your Dream Home With Our Expert interior remodeling contractors. Call Us For immaculate home renovation that fits both your lifestyle and your budget."/>
      
      </Helmet>

      <section className='about__us'>
        <h1>Interior Remodeling</h1>
        <h2>Welcome to Jayco Construction's Interior Remodeling page! We are a full-service construction firm with expertise in all areas of interior remodeling, from small renovations to large-scale projects. Our team of experienced and skilled professionals is dedicated to helping you transform your space into the home or commercial space of your dreams.</h2>
        <img src={interior1} alt="main"/>
       </section>

    <section className="structural__center">
    <h4 data-aos="zoom-in">Our services include:</h4>
       <br/><br/>
        <div className="struct__row">
            <div className="struct__col1" data-aos="fade-right">
                <h2>Home remodeling</h2>
                <p>Whether you want to renovate one room or the whole house, we can assist. We'll work with you to develop a design that embodies your unique taste and satisfies your practical requirements.</p>
                <br/>
                <h2>Kitchen Remodeling</h2>
                <p>The kitchen is frequently the center of the home, so it's important to have a well-thought-out design. Together, we'll design a kitchen that suits your unique requirements and aesthetic choices.</p>
            
            </div>
            
            <div className="struct__col1" data-aos="fade-left">
                <h2>Commercial remodeling</h2>
                <p>This includes work on retail establishments, office buildings, and more. We recognize how a well-designed commercial space can benefit your company, and we'll work with you to build a practical and appealing environment that will support the growth of your enterprise.</p>
                <br/>
                <h2>Bathroom remodeling</h2>
                <p>A bathroom redesign can enhance your space's functionality and aesthetics while also increasing the value of your house. We'll collaborate with you to design a bathroom that is both elegant and practical.</p>
 
            </div>

        </div>

           <br/><br/>
        <div className="struct__row">
               <div className="struct__col1" data-aos="fade-right">
               <img src={interior2} alt="main"/>
              </div>
               <div className="struct__col1" data-aos="fade-left">
               <img src={interior3} alt="main"/>
               </div>
            </div>
            <br/>
            <img src={interior4} alt="main" data-aos="zoom-in-up"/>
            <br/>
            <br/>
       
            <div className="struct__row">
               <div className="struct__col1" data-aos="fade-right">
               <img src={interior5} alt="main"/>
              </div>
               <div className="struct__col1" data-aos="fade-left">
               <img src={interior6} alt="main"/>
               </div>
            </div>
<br/>
            <div className="struct__row">
            <div className="struct__col1" data-aos="fade-right">
                <h2>Room Additions</h2>
                <p>We can assist you in adding a room to your house if your family is expanding or you simply need more space. We'll collaborate with you to create a design that perfectly complements your current home while also considering your unique requirements and preferences. </p>
                 <br/>
                 <h2>Flooring Services</h2>
                <p>We also provide flooring services, such as hardwood, tile, and other flooring type installation. We can assist you in choosing the ideal flooring for your area and guarantee that it will be put properly and to your satisfaction. </p>

            </div>
            
            <div className="struct__col1" data-aos="fade-left">
                <h2>Basement remodeling</h2>
                <p>Whether you want to build a media room, a home office, or a children's playroom, a finished basement can add important living space to your house. We'll collaborate with you to create a location that is both appealing and suits your unique demands. </p>
              
            </div>

        </div>
        <p data-aos="zoom-in-up">At Jayco Construction, we take great pleasure in our dedication to excellence and client satisfaction. From the initial consultation to the final walk-through, we'll work directly with you to make sure your project is finished to your satisfaction.
<br/><br/>
If you're ready to start your interior remodel project, we invite you to contact us today to schedule a consultation. Our team of professionals are here to help you create the space of your dreams.</p>
    </section>

    <section className='last__banner' data-aos="zoom-in-up">
       <div className='last__banner__width'>
       <div className='last__banner__row'>
         <div className="last__banner__width__col">
          <h2>Schedule a Consultation Now!</h2>
         </div>
         <div className="last__banner__width__colb">
          <br/>
          <h2>Transform both your commercial and residential spaces with craftsmanship </h2>
<br/>
          <a  href='contact'>Contact Us</a>
          <br/>   <br/>
         </div>
       </div>
       </div>
      </section>



    </div>
  )
}
export default InteriorRemodeling;