import React from 'react'
import {motion} from "framer-motion"
import '../components/Styles/services.css'
import {Helmet}from"react-helmet";
import service1 from '../images/service1.png';
import service2 from '../images/service2.png';
import service3 from '../images/service3.png';
import service4 from '../images/service4.png';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
 const Services =()=> {
       useEffect(()=>{
              AOS.init();
            })
  return (
    <div>
                    <Helmet>
      
      <title>Reliable Construction Company - Quality Construction Services - Jayco Constructions</title>
      <meta name="description" content="Jayco is providing top-notch construction services having a diverse team of engineers, architects, and project managers, who provide quality construction i.e, General, Structural & Home renovation services."/>
      
      </Helmet>
      <section className='about__us' data-aos="fade-left">
        <h1>Services</h1>
        <h2>Delivering top-notch services to customers is a priority for Jayco, who places a strong emphasis on quality, safety, and customer satisfaction. Experience the Jayco difference.
</h2>
        <img src={service1} alt="services"/>
   
      </section>
       
       <section className='services__center'>
       <div className='achive__a'>
          <h2>Main Services</h2>
          <hr/>
        </div>

        <div className='services__row'>

           <div className="services__col1" data-aos="fade-right">
           <img src={service2}  alt="center_first_pic"></img>
           <div className="layer">
    <h3><NavLink to="/general-construction">See More</NavLink></h3>
    <div className='touch'>
    <p>Touch  <span> <i id='ico' class="fa">&#xf1ce; </i>
   </span></p>
    </div>
    </div>
           </div>
           
           <div className="services__col2" data-aos="fade-left">
            <h2>General Construction</h2>
            <p>Jayco Construction offers top-notch general construction services for a range of projects, from conception to execution.</p>
           </div>
        
        </div>

        <div className='services__row'>

        <div className="services__col1" data-aos="fade-right">
        <img src={service3}  alt="center_first_pic"></img>
        <div className="layer">
    <h3><NavLink to="/structural">See More</NavLink></h3>
    <div className='touch'>
    <p>Touch  <span> <i id='ico' class="fa">&#xf1ce; </i>
   </span></p>
    </div>
  
    </div>
        </div>

        <div className="services__col2" data-aos="fade-left">
        <h2>Structural Engineering</h2>
        <p>The specialists at Jayco offer creative and effective structural engineering solutions for a solid foundation.
</p>
        </div>
       </div>

       <div className='services__row'>

        <div className="services__col1" data-aos="fade-right">
        <img src={service4}  alt="center_first_pic"></img>
        <div className="layer">
    <h3><NavLink to="/interior-remodeling">See More</NavLink></h3>
    <div className='touch'>
    <p>Touch  <span> <i id='ico' class="fa">&#xf1ce; </i>
   </span></p>
    </div>
  
    </div>

        </div>

        <div className="services__col2" data-aos="fade-left">
        <h2>Interior Remodeling</h2>
        <p>With Jayco Construction's excellent interior remodeling services, you can completely transform your home. Make your dream a reality.</p>
        </div>
       </div>


       </section>


       <section className='hom2__mid__3'>


       <div className='mid__3__one'>
        <h2>PRODUCT</h2>
        <hr/>
       </div>
 

        <div className='row__mid' data-aos="fade-right">
          <div className="mid__3__col">
            <h2>Solutions (Comprehensive Construction Solutions)</h2>
          </div>
          <div className="mid__3__col" data-aos="fade-right">
            <p>Choose one of our services.</p>            
          </div>
       </div>

       <div className='row__mid' data-aos="fade-right">
       <div className="mid__3a__col">
        <h2>01</h2>
       </div>      
       <div className="mid__3b__col" >
       <h2>Roofing and Exteriors Services</h2>
       <h3>We offer roofing and exterior services including installation, repair, maintenance, windows, doors, siding, gutter and downspout services.
</h3>
       </div>      
       <div className="mid__3c__col">
     <a href='general-construction'>  <i class="ri-arrow-right-line"></i></a>
       </div>      
       
        </div>

               <div className='row__mid' data-aos="fade-right">
       <div className="mid__3a__col">
        <h2>02</h2>
       </div>      
       <div className="mid__3b__col">
       <h2>Energy-efficient home construction</h2>
       <h3>We specialize in creating energy-efficient homes that are environmentally friendly and save you money, tailored to your requirements and tastes.
</h3>
       </div>      
       <div className="mid__3c__col">
       <a href='general-construction'>  <i class="ri-arrow-right-line"></i></a>
       </div>      
       
        </div>


       <div className='row__mid' data-aos="fade-right">
       <div className="mid__3a__col">
        <h2>03</h2>
       </div>      
       <div className="mid__3b__col">
       <h2>Commercial remodeling
</h2>
       <h3>We design commercial spaces for retail establishments, office buildings, etc. to benefit your company with a practical and appealing environment.
</h3>
       </div>      
       <div className="mid__3c__col">
       <a href='interior-remodeling'>  <i class="ri-arrow-right-line"></i></a>
              </div>      
       
        </div>


               <div className='row__mid' data-aos="fade-right">
       <div className="mid__3a__col">
        <h2>04</h2>
       </div>      
       <div className="mid__3b__col">
       <h2>Building Design and Analysis
</h2>
       <h3>Our team designs structurally sound buildings in compliance with rules and regulations through up-to-date assessments for sustainably withstanding all loads and stresses.
</h3>
       </div>      
       <div className="mid__3c__col">
       <a href='structural'>  <i class="ri-arrow-right-line"></i></a>
       </div>      
       
        </div> 
       
       </section>

       <section className='last__banner' data-aos="zoom-in-up">
       <div className='last__banner__width'>
       <div className='last__banner__row'>
         <div className="last__banner__width__col">
          <h2>Schedule a Consultation Now!</h2>
         </div>
         <div className="last__banner__width__colb">
          <br/>
          <h2>Transform both your commercial and residential spaces with craftsmanship  </h2>
<br/>
          <a  href='contact'>Contact Us</a>
          <br/>   <br/>
         </div>
       </div>
       </div>
      </section>





    </div>
  )
}
export default Services;